<template>
  <div id="app" class="container-fluid mt-5">
    <div class="row">
      <div
        class="topic-container col-lg-4 col-md-3 d-flex justify-content-center"
        v-for="(post, index) in posts"
        :key="index"
      >
        <div class="topic card">
          <img :src="post.image" class="card-img-top" alt="Post Image" />
          <div class="card-body">
            <h5 class="card-title">{{ post.title }}</h5>
            <p class="card-text">{{ post.description }}</p>
            <a :href="post.link" target="_blank" class="btn btn-primary"
              >Read More</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TrendingTopics",
  props: {},

  data() {
    return {
      posts: [
        {
          title: "3D Model Reconstruct + Data Link config",
          description:
            "Reconstructing 3D models and configuring data links, how to effectively rebuild models and link relevant data.",
          image:
            "https://img1.cgtrader.com/items/28017/cad6bcb80f/large/building-construction-site-3d-model-low-poly-max-obj-3ds-fbx-lwo-ma.jpg",
          link: "https://insightawpcom.sharepoint.com/:b:/s/Management/Ee8VAw3b5_xDrLw5NgXSMP0BkcsjA3CO5Tq9AQfNcXuybA?e=zOQqoa"
        },
        {
          title: "PBI - GTP3 - Power Automate",
          description:
            "How to use Power BI in combination with GPT-4 for automating the analysis of datasets, streamlining data processing and insight generation with Power Automate.",
          image:
            "https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/BlogImages/PBI-GTP4-Automate.png",
          link: "https://insightawpcom.sharepoint.com/:b:/s/Management/EeW8PX-MwahPlvjddgisBc8BoGA1dKpRfu_a3RuqcnkZaQ?e=wYwNXv"
        },
        {
          title: "PBI - Labels - Adding 'Dataset Last Modified' (SharePoint)",
          description:
            "How to label Power BI datasets with their last modified dates when the data is sourced from SharePoint files, aiding in tracking dataset updates.",
          image:
            "https://datascientest.com/en/wp-content/uploads/sites/9/2023/09/actualisation-incrementielle-powerbi-1024x555.webp",
          link: "https://insightawpcom.sharepoint.com/:b:/s/Management/ES0uUJJLgKNBnq4Gak97PqwB3dQeYV0FkW2zura_N34y4A?e=rNOLLJ"
        },
        {
          title: "PBI - Labels - Adding Dataset Last Modified SQL",
          description:
            "How to add labels indicating the last modification date of datasets sourced from SQL databases in Power BI.",
          image:
            "https://datascientest.com/en/wp-content/uploads/sites/9/2023/09/actualisation-incrementielle-powerbi-1024x555.webp",
          link: "https://insightawpcom.sharepoint.com/:b:/s/Management/EYgXx5WbI-REke4W65eXsB0BDgSkallJ2wZKxAm86u2vSw?e=tJ56dr"
        },
        {
          title: "PBI - Py - 3D - Adding a Python matplotlib chart",
          description:
            "Incorporate 3D charts created with Python's Matplotlib library into Power BI, offering more dynamic and interactive data representations.",
          image:
            "https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/BlogImages/PBI-3D-Python.png",
          link: "https://insightawpcom.sharepoint.com/:b:/s/Management/EQo8qOK8c7BHlq8U97UYQa0B2b_K4GnP-Zmk0CyMGf0Bqw?e=kYKXN3"
        },
        {
          title: "PBI - Py - Adding a Python Radar chart",
          description:
            "Integrate Python radar charts into Power BI reports, enhancing data visualization capabilities with advanced chart types.",
          image:
            "https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/BlogImages/PBI-Py-Python-Radar-chart.png",
          link: "https://insightawpcom.sharepoint.com/:b:/s/Management/EezDxa_QgwdGkrMos0VcagQBw2txA8DglRC16gw8BDPUaQ?e=TNmOOt"
        },
        {
          title: "PBI - Py - Calendar Heat Map",
          description:
            "Create and add calendar heat maps to Power BI reports using Python, offering a visual method to represent data trends over time.",
          image:
            "https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/BlogImages/PowerBI-Python-CalendarHeatMap.png",
          link: "https://insightawpcom.sharepoint.com/:b:/s/Management/EfBvcfXgfTBChf16lwIXDGwB5p_iBBcqJOoOmr7LD_QJag?e=z6l3V7"
        },
        {
          title: "PBI - Py - Initial Setup",
          description: "How to setup Python for PowerBI Reports.",
          image:
            "https://www.freecodecamp.org/news/content/images/size/w2000/2022/08/Python-Power-BI-1.png",
          link: "https://insightawpcom.sharepoint.com/:b:/s/Management/EVHu4lXOIwFLhJH55FS1ib8BW6RyiXA4ynLWz2kuc19shQ?e=5BDCM5"
        },
        {
          title:
            "PBI - Py - Scatter Plot - Supplier Forecast Accuracy Visualization",
          description:
            "How to create scatter plots in Power BI using Python, specifically for visualizing supplier forecast accuracy.",
          image:
            "https://myerpfuncstorage.blob.core.windows.net/erpwebsiteimages-prod/BlogImages/PBI-Python-ScatterPlot.png",
          link: "https://insightawpcom.sharepoint.com/:b:/s/Management/EYb0AyJkjzZHlG5Su5t1VjQBpP5MZL7QIeqqW9Yeljnz5w?e=SnPQsT"
        }
      ]
    };
  }
};
</script>

<style scoped>
.topic-container {
  max-width: 480px;
  padding-left: 3rem;
}
.topic {
  max-width: 400px;
}
.card-img-top {
  width: 400px;
  height: 400px;
  object-fit: contain;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

.card-body {
  border: 1px solid #e2e2e2;
  width: 100%;
  margin-left: 8%;
  margin-top: -25%;
  background-color: #ffffff;

  min-height: 250px;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

@media (max-width: 1520px) {
  .topic-container {
    max-width: 450px;
    padding-left: 2rem;
  }
  .topic {
    padding-bottom: 3rem;
  }

  .card-img-top {
    width: 350px;
    height: 350px;
  }

  .card-body {
    width: 85%;
    margin-left: 11.5%;
    margin-top: -23%;
    background-color: #ffffff;

    min-height: 250px;
  }
}
</style>
