<template>
  <div class="container-fluid main-container">
    <img
      src="@/assets/images/newHome/Background-Doodle.png"
      class="background-doodle"
      alt="background doodle"
    />
    <img
      src="@/assets/images/newHome/InsightImg.png"
      alt="logo"
      class="new-logo"
    />

    <div v-if="currentUser === null || !currentUser">
      <div class="row" v-if="errorMessages && errorMessages.length">
        <div class="col-8">
          <div class="alert alert-danger">
            <ul>
              <li :key="error" v-for="error in errorMessages">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row main">
        <!-- <div class="col-12 d-flex justify-content-center align-items-center">
          <span class="text-center phrase">The Future is Smart</span>
        </div> -->
        <div class="col-12 d-flex justify-content-center align-items-center">
          <button
            class="btn btn-green btn-spl stretched-link"
            v-on:click="$emit('fetchUser')"
            href="#"
            role="button"
          >
            Log In
          </button>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="row" v-if="errorMessages && errorMessages.length">
        <div class="col-8">
          <div class="alert alert-danger">
            <ul>
              <li :key="error" v-for="error in errorMessages">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row main">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <span class="text-center phrase">The Future is Smart</span>
        </div>

        <div class="col-12 d-flex justify-content-center align-items-center">
          <div class="box">
            <div class="overlap-group">
              <div
                class="rectangle"
                @click="$router.push({ name: 'emp-add-timesheet' })"
              >
                <img
                  src="@/assets/images/newHome/timeIcon.png"
                  class="material-symbols"
                />
                <div class="text-wrapper">Add Timesheet</div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="overlap-group">
              <div
                class="rectangle"
                @click="$router.push({ name: 'emp-add-expense' })"
              >
                <img
                  src="@/assets/images/newHome/ExpenseIcon.png"
                  class="material-symbols"
                />
                <div class="text-wrapper">Add Expense</div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-12 d-flex justify-content-center align-items-center textContainer"
        >
          <TextContainer />
        </div>
      </div>
    </div>

    <!-- <div
      class="topics row w-90 m-auto"
      id="trendingTopics"
      v-if="currentUser !== null || currentUser"
    >
      <div class="col-12 d-flex justify-content-center align-items-center">
        <img
          v-if="!isUserScrolling"
          src="@/assets/images/newHome/arrow-down.png"
          class="arrow-down"
          alt="Down Arrow"
        />
        <span class="text-center sm-phrase">Trending Topics</span>
        <img
          v-if="!isUserScrolling"
          src="@/assets/images/newHome/arrow-down.png"
          class="arrow-down"
          alt="Down Arrow"
        />
      </div>

      <div class="col-12 d-flex justify-content-center align-items-center">
        <TrendingTopics />
      </div>
    </div> -->
  </div>
</template>

<script>
import TextContainer from "@/components/TextContainer.vue";
import TrendingTopics from "@/components/TrendingTopics.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TextContainer
    // TrendingTopics
  },
  data() {
    return {
      isUserScrolling: false,
      errorMessages: {}
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      currentUser: "getLoggedInUser"
    }),

    isAdmin() {
      return this.isLoggedIn && this.currentUser.isAdmin;
    },

    isLoggedIn() {
      return this.currentUser !== null;
    }
  },
  async created() {
    window.addEventListener("scroll", this.handleScroll);

    this.setLoading(true);
    this.checkIsLoggedIn()
      .catch((e) => console.error(e))
      .finally((e) => this.setLoading(false));
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      this.isUserScrolling = window.scrollY > 0;
    }
  }
};
</script>

<style>
.background-doodle {
  position: absolute;
  top: 50;
  right: 0;
  z-index: -1;
  height: 70%;
  scale: 1.5;

  transition: transform 0.5s ease-in-out;
}

.background-doodle:hover {
  /* make it tilt in z axis */
  transform: rotateZ(8deg);
}

.arrow-down {
  width: 35px;
  height: 35px;

  transition: all 1s ease-in-out;
}

.new-logo {
  position: absolute;
  top: 30%;
  right: 8%;
  z-index: 1;
  height: 30%;

  transition: transform 0.5s ease-in-out;
}

.background-doodle:hover ~ .logo {
  /* make it tilt in z axis */
  transform: rotateZ(-8deg);
}

.main {
  padding-top: 2vw !important;
  width: 75%;
}

.phrase {
  color: #000;
  font-size: 6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Open Sans", sans-serif;
}

.sm-phrase {
  color: #000;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Open Sans", sans-serif;

  padding-inline: 2rem !important;
}

.stretched-link {
  margin-top: 8rem !important;
  margin-bottom: 4rem !important;
  font-size: 30px;
}

.box {
  height: 142px;
  width: 301px;

  padding-top: 2.8rem;
  margin-left: 3rem;
}

.box .overlap-group {
  height: 142px;
  position: relative;
  width: 301px;
}

.box .rectangle {
  background-color: #ffffff;
  border: 3px solid;
  border-color: #000000;
  border-radius: 42px;
  height: 140px;
  position: absolute;
  transition: all 0.2s ease;
  width: 300px;

  cursor: pointer;
}

.box .rectangle:hover {
  background-color: #84b552d3;
  border: 0px solid;
  border-color: rgba(0, 0, 0, 0);

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.box .material-symbols {
  height: 56px !important;
  left: 38px !important;
  position: absolute !important;
  top: 38px !important;
  width: 56px !important;
}

.box .text-wrapper {
  color: #000000;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 700;
  height: 68px;
  left: 124px;
  letter-spacing: 0.84px;
  line-height: normal;
  position: absolute;
  top: 37px;
  width: 145px;
}

.textContainer {
  margin-top: 4rem;
  margin-left: 0rem;
}

.topics {
  margin-top: 10rem !important;
}

@media (max-width: 1520px) {
  .main {
    padding-top: 1.5vw !important;
    width: 80%;
  }

  .phrase {
    font-size: 4.29rem;
  }

  .sm-phrase {
    font-size: 2rem;
  }
  .stretched-link {
    margin-top: 8rem !important;
    margin-bottom: 18rem !important;
  }

  .box {
    height: 100px;
    width: 215px;

    padding-top: 1.7rem;
    margin-left: 2.14rem;
  }

  .box .overlap-group {
    height: 100px;
    width: 215px;
  }

  .box .rectangle {
    height: 98px;
    border-radius: 29.57px;
    width: 214px;
  }

  .box .rectangle:hover {
    box-shadow: 0px 2.86px 2.86px rgba(0, 0, 0, 0.25);
  }

  .box .material-symbols {
    height: 40px !important;
    left: 26.86px !important;
    top: 26.86px !important;
    width: 40px !important;
  }

  .box .text-wrapper {
    font-size: 17.14px;
    height: 47.71px;
    left: 87.71px;
    top: 25.14px;
    letter-spacing: 0.59px;
    width: 100.71px;
  }

  .textContainer {
    margin-top: 2rem;
  }

  .topics {
    margin-top: 4.14rem !important;
  }
}
</style>
