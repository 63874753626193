<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="mt-3 ml-3">Employee - Project Roster</h1>
      <b-button
        v-if="canEdit"
        @click="saveData"
        variant="primary"
        class="ml-auto mr-3 mt-3"
        :disabled="loading"
      >
        Save
      </b-button>
    </div>

    <div class="filters mt-3 ml-3 d-flex align-items-end">
      <b-form-group label="Filter by Project" class="mb-0">
        <b-form-select
          v-model="selectedProject"
          :options="projectOptions"
          @change="filterRoster"
          class="interactive-dropdown"
          size="lg"
        >
        </b-form-select>
      </b-form-group>

      <b-form-group label="Filter by Employee" class="ml-3 mb-0">
        <b-form-select
          v-model="selectedEmployee"
          :options="employeeOptions"
          @change="filterRoster"
          class="interactive-dropdown"
          size="lg"
        >
        </b-form-select>
      </b-form-group>

      <b-button
        variant="secondary"
        class="ml-3 reset-button"
        @click="resetFilters"
      >
        Reset Filters
      </b-button>
    </div>

    <div class="tableDiv">
      <table>
        <thead>
          <tr>
            <th class="employee-name sticky-col">Employee Name</th>
            <td
              v-for="project in filteredProjects"
              :key="project.ProjectID"
              class="project-name"
            >
              {{ project.ProjectName }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="employee in filteredEmployees" :key="employee.id">
            <td class="employee-name sticky-col">{{ employee.Name }}</td>
            <td
              v-for="project in filteredProjects"
              :key="project.ProjectID"
              class="checkbox-cell"
            >
              <input
                type="checkbox"
                :checked="isAllowedToBill(employee.id, project.ProjectID)"
                v-if="canEdit"
                @change="updateIsAllowedToBill(employee.id, project.ProjectID)"
              />
              <span v-else>
                {{
                  isAllowedToBill(employee.id, project.ProjectID) ? "Yes" : ""
                }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    loading: {
      default: false
    }
  },
  data() {
    return {
      employees: [],
      projects: [],
      filteredEmployees: [],
      filteredProjects: [],
      selectedProject: "",
      selectedEmployee: "",
      isAllowedToBillData: [],
      updatedIsAllowedToBillData: []
    };
  },
  mounted() {
    this.getEmployees();
    this.getProjects();
    this.getRoaster();
  },
  computed: {
    projectOptions() {
      return [
        { value: "", text: "Select Project" },
        ...this.projects.map((project) => ({
          value: project.ProjectID,
          text: project.ProjectName
        }))
      ];
    },
    employeeOptions() {
      return [
        { value: "", text: "Select Employee" },
        ...this.employees.map((employee) => ({
          value: employee.id,
          text: employee.Name
        }))
      ];
    },
    canEdit() {
      return (
        this.currentUser.username === "brian.kean@insight-awp.com" ||
        this.currentUser.username === "gregorio.labbozzetta@insight-awp.com" ||
        this.currentUser.username === "navjot.randhawa@insight-awp.com"
      );
    },
    ...mapGetters({
      currentUser: "getLoggedInUser"
    })
  },
  methods: {
    async getEmployees() {
      this.setLoading(true);
      try {
        const res = await this.$axios.get(`/activeStaffs`);
        if (res && res.data.length > 0) {
          this.employees = res.data;
        }
        this.filteredEmployees = this.employees;
        this.setLoading(false);
      } catch (err) {
        console.error(err);
      }
    },
    async getProjects() {
      this.setLoading(true);
      try {
        const res = await this.$axios.get(`/projects?sort_by=ProjectName`);
        if (res && res.data.length > 0) {
          this.projects = res.data;
        }
        this.filteredProjects = this.projects;
        this.setLoading(false);
      } catch (err) {
        console.error(err);
      }
    },
    resetFilters() {
      this.selectedProject = "";
      this.selectedEmployee = "";
      this.filterRoster();
    },
    filterRoster() {
      this.filteredEmployees = this.selectedEmployee
        ? this.employees.filter(
            (employee) => employee.id === this.selectedEmployee
          )
        : this.employees;

      this.filteredProjects = this.selectedProject
        ? this.projects.filter(
            (project) => project.ProjectID === this.selectedProject
          )
        : this.projects;
    },
    async getRoaster() {
      try {
        const res = await this.$axios.get(`/peRoaster`);
        if (res && res.data.length > 0) {
          const data = res.data;
          let newData = [];
          for (let i = 0; i < data.length; i++) {
            newData.push({
              employeeId: data[i].empId,
              projectId: data[i].projectId,
              isAllowedToBill: data[i].canBillHours
            });
          }
          this.isAllowedToBillData = newData;
        }
      } catch (err) {
        console.error(err);
      }
    },
    isAllowedToBill(employeeId, projectId) {
      const record = this.isAllowedToBillData.find(
        (entry) =>
          entry.employeeId === employeeId && entry.projectId === projectId
      );
      return record ? record.isAllowedToBill : false;
    },
    updateIsAllowedToBill(employeeId, projectId) {
      const record = this.isAllowedToBillData.find(
        (ent) => ent.employeeId === employeeId && ent.projectId === projectId
      );
      const wasUpdated = this.updatedIsAllowedToBillData.find(
        (ent) => ent.employeeId === employeeId && ent.projectId === projectId
      );
      if (wasUpdated) {
        //remove the record from updatedIsAllowedToBillData
        this.updatedIsAllowedToBillData =
          this.updatedIsAllowedToBillData.filter(
            (ent) =>
              ent.employeeId !== employeeId || ent.projectId !== projectId
          );
      } else {
        if (record) {
          this.updatedIsAllowedToBillData.push({
            employeeId,
            projectId,
            isAllowedToBill: !record.isAllowedToBill
          });
        } else {
          this.updatedIsAllowedToBillData.push({
            employeeId,
            projectId,
            isAllowedToBill: true
          });
        }
      }
      console.log("updated Data", this.updatedIsAllowedToBillData);
    },
    async saveData() {
      this.setLoading(true);
      try {
        await this.$axios.post(`/peRoaster`, this.updatedIsAllowedToBillData);
      } catch (err) {
        let data = err.response && err.response.data;
        if (data && data.message) {
          return this.displayError(data.message);
        }
        this.displayError("Failed to save Roaster record, please try later.");
      } finally {
        this.setLoading(false);
      }
      this.makeToast("success", "Roaster has been saved!");
      window.location.reload();
    }
  }
};
</script>

<style scoped>
/* Container for dropdowns and buttons */
.filters {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

/* Updated dropdown style with consistent size */
.interactive-dropdown {
  width: 350px; /* Fixed width for all dropdowns */
  border-radius: 8px;
  border: none;
  padding: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

/* Dropdown hover and focus effects */
.interactive-dropdown:hover,
.interactive-dropdown:focus {
  background-color: #e9ecef;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Dropdown item style */
.interactive-dropdown .dropdown-item {
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

/* Dropdown item hover effect */
.interactive-dropdown .dropdown-item:hover {
  background-color: #dee2e6;
}

/* Reset button style */
.reset-button {
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  padding: 8px 20px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reset-button:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Table styles */
.tableDiv {
  overflow: auto;
  margin: 20px;
  min-width: 20%;
  max-width: 100%;
  max-height: 81vh;
  scrollbar-width: thin;
  scrollbar-color: #dddddd #f9f9f9;
}

table {
  min-width: 20%;
  max-width: 100%;
  border-collapse: collapse;
  border: 0;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

.employee-name,
.project-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .employee-name {
  width: 10px !important;
} */

/* .checkbox-cell {
  width: 10px !important;
} */

.checkbox-cell input[type="checkbox"] {
  margin: auto;
  display: block;
  transition: transform 0.2s ease-in-out;
}

.checkbox-cell input[type="checkbox"]:checked {
  transform: scale(1.2);
}

thead {
  z-index: 100;
  background-color: #f2f2f2;
}

th {
  background-color: #f2f2f2;
  position: sticky;
  left: 0;
  top: 0 !important;
  z-index: 100;
  border-left: 0;
}

td:first-child {
  position: sticky;
  left: 0;
  background-color: #f9f9f9;
  z-index: 99;
  border-left: 0;
}
</style>
