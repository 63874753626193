export default class ProjectData {
  constructor(
    //id = -1,
    projectId = null,
    clientId = 0,
    projectName = "",
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    state = "",
    countryRegion = "",
    zipPostalCode = "",
    phone = null,
    contactName1 = null,
    contactName2 = null,
    contactEmail1 = null,
    contactEmail2 = null,
    clientCC1 = null,
    clientCC2 = null,
    clientBcc1 = null,
    clientBcc2 = null,
    projectStart = null,
    projectEnd = null,
    internalBillingCode = null,
    projectPO = null,
    projectPOValue = null,
    poHourlyRate = null,
    projectNetwork = null,
    vendorNumber = null,
    invoiceCurrency = null,
    billingCycle = null,
    isActive = 1
  ) {
    this.id = projectId;
    this.clientId = clientId;
    this.projectName = projectName;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.city = city;
    this.state = state;
    this.countryRegion = countryRegion;
    this.zipPostalCode = zipPostalCode;
    this.phone = phone;
    this.contactName1 = contactName1;
    this.contactName2 = contactName2;
    this.contactEmail1 = contactEmail1;
    this.contactEmail2 = contactEmail2;
    this.clientCC1 = clientCC1;
    this.clientCC2 = clientCC2;
    this.clientBcc1 = clientBcc1;
    this.clientBcc2 = clientBcc2;
    this.projectStart = projectStart;
    this.projectEnd = projectEnd;
    this.internalBillingCode = internalBillingCode;
    this.projectPO = projectPO;
    this.projectPOValue = projectPOValue;
    this.poHourlyRate = poHourlyRate;
    this.projectNetwork = projectNetwork;
    this.vendorNumber = vendorNumber;
    this.invoiceCurrency = invoiceCurrency;
    this.billingCycle = billingCycle;
    this.isActive = isActive;
  }
  /**
   * handles transformation from the api,
   * into the data class properties.
   * @param {object} ProjectData
   */
  static fromApi(data) {
    return new ProjectData(
      data.ProjectID,
      data.ClientID,
      data.ProjectName,
      data.AddressLine1,
      data.AddressLine2,
      data.City,
      data.StateProvince,
      data.CountryRegion,
      data.ZipPostalCode,
      data.Phone,
      data.ContactName1,
      data.ContactName2,
      data.ContactEmail1,
      data.ContactEmail2,
      data.ClientCC1,
      data.ClientCC2,
      data.ClientBCC1,
      data.ClientBCC2,
      data.ProjectStart,
      data.ProjectEnd,
      data.InternalBillingCode,
      data.ProjectPO,
      data.POValue,
      data.POHourlyRate,
      data.ProjectNetwork,
      data.VendorNumber,
      data.InvoiceCurrency,
      data.BillingCycle,
      data.is_active
    );
  }
  /**
   * Changes the object into an object ready to be consumed by the api
   */
  toApi() {
    return {
      //id: this.projectId,
      ProjectID: this.id,
      ClientID: this.clientId,
      ProjectName: this.projectName,
      AddressLine1: this.addressLine1,
      AddressLine2: this.addressLine2,
      City: this.city,
      StateProvince: this.state,
      CountryRegion: this.countryRegion,
      ZipPostalCode: this.zipPostalCode,
      Phone: this.phone,
      ContactName1: this.contactName1,
      ContactName2: this.contactName2,
      ContactEmail1: this.contactEmail1,
      ContactEmail2: this.contactEmail2,
      ClientCC1: this.clientCC1,
      ClientBCC1: this.clientBcc1,
      ClientBCC2: this.clientBcc2,
      ProjectNetwork: this.projectNetwork,
      ProjectStart: this.projectStart,
      ProjectEnd: this.projectEnd,
      VendorNumber: this.vendorNumber,
      InternalBillingCode: this.internalBillingCode,
      ProjectPO: this.projectPO,
      POValue: this.projectPOValue,
      POHourlyRate: this.poHourlyRate,
      InvoiceCurrency: this.invoiceCurrency,
      BillingCycle: this.billingCycle,
      is_active: this.isActive
    };
  }
}
